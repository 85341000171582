<template>
  <div class="click-mix-report">
    <div class="title">
      <h2>Click Mix Report</h2>
    </div>
    <div class="bgf0" style="padding:16px;">
      <el-tabs type="border-card">
        <el-tab-pane label="Mix Report" lazy>
          <mix-report
            :affiliateLoading="affiliateLoading"
            :affiliatesGroupList="affiliatesGroupList"
          />
        </el-tab-pane>
        <el-tab-pane label="Mix Config" lazy>
          <mix-config
            :affiliateLoading="affiliateLoading"
            :affiliatesGroupList="affiliatesGroupList"
          />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
  import MixReport from './components/MixReport.vue';
  import MixConfig from './components/MixConfig.vue';
  import { mapState, mapActions } from 'vuex';

  export default {
    components: {
      MixReport,
      MixConfig,
    },
    computed: {
      ...mapState('affiliate', {
        affiliatesGroupList: (state) => state.affiliatesGroupList,
        affiliateLoading: (state) => state.affiliateLoading,
      }),
    },
    mounted() {
      this.getAffiliateList();
    },
    methods: {
      ...mapActions('affiliate', ['getAffiliateList']),
    },
  };
</script>
<style></style>
