<template>
  <div class="mix-config">
    <el-row type="flex">
      <el-col :span="24">
        <el-card class="pad30">
          <el-form v-model="filter" :inline="true">
            <el-row :gutter="24">
              <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="5">
                <el-form-item label="OfferIds">
                  <el-input v-model="filter.offerIds" />
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="5">
                <el-form-item label="AffiliateIds">
                  <group-select
                    v-model="filter.affiliateIds"
                    :data-source="affiliatesGroupList"
                    multiple
                    reserve-keyword
                    :loading="affiliateLoading"
                    clearable
                    filterable
                    placeholder="请选择"
                  />
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="5">
                <el-form-item>
                  <el-button type="primary" @click="getDataList(1)">Search</el-button>
                  <el-button type="primary" @click="addMixConfigClick">Add</el-button>
                  <el-button type="primary" @click="batchDelMixConfig">Delete</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <el-card>
      <el-table
        :data="dataList"
        :header-cell-style="() => 'text-align:center'"
        :cell-style="() => 'text-align:center'"
        @selection-change="handleSelectionChange"
        v-loading="tableLoading"
        height="70vmin"
        stripe
        border
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="offerId" label="Offer ID" width="100"></el-table-column>
        <el-table-column prop="type" label="Type" min-width="100">
          <template slot-scope="scope">
            <template v-for="item in typeOption">
              <span :key="item.value" v-if="item.value === scope.row.type">{{ item.label }}</span>
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="sendAffiliate" label="Send Aff" min-width="100"></el-table-column>
        <el-table-column prop="sendAffsub" label="Send Aff Sub" min-width="120"></el-table-column>
        <el-table-column prop="mixAffiliate" label="Mix Aff" min-width="100"></el-table-column>
        <el-table-column prop="mixAffsub" label="Mix Aff Sub" min-width="100"></el-table-column>
        <el-table-column prop="mixCount" label="Mix Count" min-width="100"></el-table-column>
        <el-table-column prop="weight" label="Weight" min-width="100"></el-table-column>
        <el-table-column prop="createBy" label="Creator" width="100"></el-table-column>
        <el-table-column prop="createTime" label="CreateTime" width="170"></el-table-column>
      </el-table>
      <pagination
        class="block pagePanel"
        :pageTotal="total"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :pageSize="pageParam.pageSize"
        :currentPage="pageParam.page"
      ></pagination>
    </el-card>
    <el-dialog title="Add Mix Config" :visible.sync="dialogVisible" width="40%">
      <el-form :model="mixConfig" inline label-width="100px">
        <el-row type="flex">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="Offer ID">
              <el-input v-model="mixConfig.offerId" />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="Type">
              <el-select v-model="mixConfig.type" style="max-width:175px">
                <el-option
                  v-for="item in typeOption"
                  :label="item.label"
                  :key="item.value"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="Send Aff">
              <el-input v-model="mixConfig.sendAffiliate" />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="Send Aff Sub">
              <el-input v-model="mixConfig.sendAffsub" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="Mix Aff">
              <el-input v-model="mixConfig.mixAffiliate" />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="Mix Aff Sub">
              <el-input v-model="mixConfig.mixAffsub" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="Mix Count">
              <el-input v-model="mixConfig.mixCount" />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="Weight">
              <el-input v-model="mixConfig.weight" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addMixConfig">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import Pagination from '@/components/pagination';
  import GroupSelect from '@/components/GroupSelect';

  import { getMixConfigList, addMixConfig, deleteConfig } from 'api/report';

  export default {
    components: {
      Pagination,
      GroupSelect,
    },
    props: {
      affiliatesGroupList: {
        type: Array,
        default: () => [],
      },
      affiliateLoading: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        filter: {},
        dataList: [],
        pageParam: {
          page: 1,
          pageSize: 20,
        },
        total: 0,
        tableLoading: false,
        startToEndDate: [],
        typeOption: [
          {
            label: 'click',
            value: 0,
          },
          {
            label: 'conversion',
            value: 1,
          },
        ],
        multipleSelection: [],
        dialogVisible: false,
        mixConfig: {},
      };
    },
    methods: {
      getDataList(curPage) {
        if (curPage) {
          this.currentPage = curPage;
        }
        const param = {
          ...this.pageParam,
        };
        for (const key in this.filter) {
          if (this.filter[key]) {
            if (Array.isArray(this.filter[key])) {
              this.filter[key].length > 0 && (param[key] = this.filter[key].toString());
            } else {
              param[key] = this.filter[key];
            }
          }
        }
        this.tableLoading = true;
        getMixConfigList(param)
          .then((response) => {
            if (response.code === 200) {
              this.dataList = response.result;
              this.total = response.total;
            }
          })
          .finally(() => {
            this.tableLoading = false;
          });
      },
      addMixConfigClick() {
        this.dialogVisible = true;
        this.mixConfig = {};
      },
      addMixConfig() {
        addMixConfig({ ...this.mixConfig }).then((response) => {
          if (response.code === 200) {
            this.$message.success('Add Success');
            this.getDataList();
            this.dialogVisible = false;
          } else {
            this.$message.error(response.message);
          }
        });
      },
      batchDelMixConfig() {
        if (this.multipleSelection.length === 0) {
          this.$message.warning('请先选择要删除的数据');
          return;
        }
        const ids = this.multipleSelection.map((item) => item.id).toString();
        deleteConfig(ids).then((response) => {
          if (response.code === 200) {
            this.$message.success('Delete Success');
            this.getDataList();
          } else {
            this.$message.error(response.message);
          }
        });
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      handleSizeChange(size) {
        this.pageParam.pageSize = size;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(currentPage) {
        this.pageParam.page = currentPage;
        this.commonReportApi();
      },
    },
  };
</script>

<style></style>
