<template>
  <div class="mix-report">
    <el-row type="flex">
      <el-col :span="24">
        <el-card class="pad30">
          <el-form v-model="filter" :inline="true">
            <el-row :gutter="24">
              <el-col :xs="24" :sm="12" :lg="8" :xl="5">
                <el-form-item label="Date">
                  <el-date-picker
                    v-model="startToEndDate"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    type="daterange"
                    style="width:260px;"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :lg="6" :xl="5">
                <el-form-item label="OfferIds">
                  <el-input v-model="filter.offerIds" />
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :lg="7" :xl="5">
                <el-form-item label="AffiliateIds">
                  <group-select
                    v-model="filter.affiliateIds"
                    :data-source="affiliatesGroupList"
                    multiple
                    reserve-keyword
                    :loading="affiliateLoading"
                    clearable
                    filterable
                    placeholder="请选择"
                  />
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :lg="5" :xl="5">
                <el-form-item>
                  <el-button type="primary" @click="getDataList(1)">Search</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <el-card>
      <el-table
        :data="dataList"
        :header-cell-style="() => 'text-align:center'"
        :cell-style="() => 'text-align:center'"
        v-loading="tableLoading"
        height="70vmin"
        stripe
        border
      >
        <el-table-column prop="offerId" label="Offer ID" width="100"></el-table-column>
        <el-table-column prop="sendAffiliate" label="Send Aff" min-width="100"></el-table-column>
        <el-table-column prop="sendAffsub" label="Send Aff Sub" min-width="120"></el-table-column>
        <el-table-column prop="mixAffiliate" label="Mix Aff" min-width="100"></el-table-column>
        <el-table-column prop="mixAffsub" label="Mix Aff Sub" min-width="100"></el-table-column>
        <el-table-column prop="clickCap" label="Click Cap" min-width="100"></el-table-column>
        <el-table-column prop="clickCount" label="Click Count" min-width="100"></el-table-column>
        <el-table-column
          prop="conversionCap"
          label="Conversion Cap"
          min-width="150"
        ></el-table-column>
        <el-table-column prop="conversionCount" label="Conversion Count" min-width="150">
        </el-table-column>
        <el-table-column prop="reportDate" label="Date" width="100"> </el-table-column>
      </el-table>
      <pagination
        class="block pagePanel"
        :pageTotal="total"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :pageSize="pageParam.pageSize"
        :currentPage="pageParam.page"
      ></pagination>
    </el-card>
  </div>
</template>

<script>
  import { myMixin } from '@/mixins/mixins.js';
  import Pagination from '@/components/pagination';
  import GroupSelect from '@/components/GroupSelect';

  import { getMixReportList } from 'api/report';
  import dateUtils from '@/utils/dateutils.js';

  export default {
    mixins: [myMixin],
    components: {
      Pagination,
      GroupSelect,
    },
    props: {
      affiliatesGroupList: {
        type: Array,
        default: () => [],
      },
      affiliateLoading: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        filter: {},
        dataList: [],
        pageParam: {
          page: 1,
          pageSize: 20,
        },
        total: 0,
        tableLoading: false,
        startToEndDate: [],
      };
    },
    mounted() {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() + 3600 * 1000 * 24);
      this.startToEndDate = [];
      this.startToEndDate[0] = dateUtils.date2StrYMD(end);
      this.startToEndDate[1] = dateUtils.date2StrYMD(start);
    },
    methods: {
      getDataList(curPage) {
        if (curPage) {
          this.currentPage = curPage;
        }
        const param = {
          ...this.pageParam,
        };
        for (const key in this.filter) {
          if (this.filter[key]) {
            if (Array.isArray(this.filter[key])) {
              this.filter[key].length > 0 && (param[key] = this.filter[key].toString());
            } else {
              param[key] = this.filter[key];
            }
          }
        }
        if (this.startToEndDate.length === 2) {
          const startDate = this.startToEndDate[0];
          const endDate = this.startToEndDate[1];
          param.fromDate = startDate;
          param.toDate = endDate;
        }
        this.tableLoading = true;
        getMixReportList(param)
          .then((response) => {
            if (response.code === 200) {
              this.dataList = response.result;
              this.total = response.total;
            }
          })
          .finally(() => {
            this.tableLoading = false;
          });
      },
      handleSizeChange(size) {
        this.pageParam.pageSize = size;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(currentPage) {
        this.pageParam.page = currentPage;
        this.commonReportApi();
      },
    },
  };
</script>

<style></style>
